<template> </template>

<script>
export default {
    async created() {
        const {slug} = this.$route.params
        this.startSlug = slug
    },
    computed: {
        startSlug: {
            get() {
                return this.$store.state.tutorial.startSlug
            },

            set(value) {
                this.$store.commit('tutorial/SET_STARTSLUG', value)
            },
        },
    },
}
</script>

<style></style>
